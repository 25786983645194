@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@500&family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

$offwhite: rgb(243, 243, 243);
$bodybg: rgba(243, 243, 243, 0.83);
$offblack: rgb(40, 40, 40);
$pattern: url("./images/NST_patternUp.png");

$headerFont: "Lora", serif;
$subFont: "Roboto", sans-serif;

html {
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bodybg;
  background-image: $pattern;
  background-size: 1000px 400px;
  background-repeat: repeat;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  background-color: $offwhite;
  width: 100vw;
  margin-left: 0 !important;
  height: 100px;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 0 !important;
  .navRow {
    width: 100vw;
    margin: 0;
    .navItem {
      margin-top: 20px;
      height: 80px;
      .navheader {
        margin-left: 150px;
        margin-top: -8px !important;
        &:hover {
          border-bottom: none;
        }
        img {
          height: 70px;
        }
      }
      a {
        height: 100%;
        font-family: $headerFont;
        color: $offblack !important;
        font-size: 16pt;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: none;
          border-bottom: 2px solid $offblack;
        }
      }
    }
  }
}
.modal-container {
  h1 {
    font-family: $headerFont;
    margin-left: 20px;
  }
  .modal-body {
    img {
      width: 100%;
    }
    iframe {
      height: 480px;
    }
    p {
      margin-top: 30px !important;
      font-family: $subFont;
    }
  }
}

.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

.home__content-wrapper {
  background-color: $bodybg;
  width: 100vw;
  .fullcar {
    .carimagediv {
      margin-top: 100px;
      width: 100vw;
      height: 85vh;
      position: relative;
      overflow: hidden;
      .carimage {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: -120px;
        transform: scaleX(-1);
      }
      .carimage1 {
        width: 100%;
        object-fit: cover;
        position: absolute;
        top: -160px;
      }
      .carimage2 {
        top: -100px;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }
    }
    .carcaption {
      background-color: rgba(30, 30, 30, 0.6);
      width: 100vw;
      margin-left: -15vw !important;
      margin-bottom: -20px;
      padding-bottom: 50px !important;
      z-index: 0;
      h3 {
        font-size: 40pt;
        font-family: $headerFont;
      }
      p {
        font-size: 18pt;
        font-family: $subFont;
      }
    }
  }
  .welcomeContainer {
    margin-top: 80px;
    margin-bottom: 85px;
    .welcomeHeader {
      font-family: $headerFont;
      font-size: 45pt;
      margin-bottom: 40px;
      text-align: center;
      span {
        font-size: 35pt;
      }
    }
    iframe {
      height: 100%;
    }
    div {
      padding-bottom: 0;
    }
    .divRight {
      background-image: linear-gradient(
        rgba(245, 245, 245, 0.8),
        rgba(227, 227, 235, 0.8)
      );
      // display: flex;
      align-items: center;
      padding: 40px 70px;
      border-radius: 4px;
      box-shadow: 0px 0px 10px rgba(136, 136, 136, 0.2);
      h2 {
        font-size: 20pt;
        font-family: $subFont;
        color: $offblack;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        width: 50%;
        padding-bottom: 5px;
      }
      ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
        margin-left: 120px;
        li {
          padding-right: 15px;
        }
      }
    }
  }
  .floorPlansContainer {
    max-width: 100vw !important;
    background-color: rgb(235, 235, 235);
    margin-top: -10px;
    padding-top: 90px !important;
    padding-bottom: 80px;
    h2 {
      font-family: $headerFont;
      font-size: 45pt;
      margin-bottom: 0;
      margin-left: 0;
      margin-top: 0px;
    }
    .floor-plans {
      padding-top: 60px;
      padding-left: 100px;
      padding-right: 40px;
      .tabs {
        border-bottom: 0.5px solid rgba(140, 140, 140, 0.75);
        .active {
          border-left: 0.5px solid rgba(140, 140, 140, 0.75);
          border-right: 0.5px solid rgba(140, 140, 140, 0.75);
          border-top: 0.5px solid rgba(140, 140, 140, 0.75);
          background-color: rgb(235, 235, 235);
        }
        button {
          border: none;
          background-color: rgba(245, 245, 245, 0);
          border-bottom: none;
          margin-bottom: -0.5px;
          padding: 10px 20px;
          margin-left: 3px;
          border-radius: 3px 3px 0px 0px;
          font-family: $subFont;
          font-size: 12pt;
          &:hover {
            border-left: 0.5px solid rgba(151, 151, 151, 0.8);
            border-right: 0.5px solid rgba(151, 151, 151, 0.8);
            border-top: 0.5px solid rgba(151, 151, 151, 0.8);
          }
        }
      }
      .floorPlanTable {
        margin-top: 20px;
        border-top: none;
        text-align: center;
        .bed-bath-head-mobile {
          display: none;
        }
        .bed-bath-head-dtop {
          display: table-cell;
        }
        thead {
          border-top: none;
          font-family: $headerFont;
          tr {
            border-bottom: 1px solid rgba(50, 50, 50, 0.9) !important;
          }
          th {
            border-top: none;
            padding-bottom: 5px;
            text-align: center;
            font-size: 18pt !important;
          }
        }
        .tableCell {
          font-size: 14pt !important;
          color: rgb(50, 50, 50) !important;
          .fpLink {
          }
        }
        .desc-cell {
          max-width: 300px;
        }
      }
    }
  }
  .fpCar {
    width: 80%;
    margin-left: 10%;
    .leftFade {
      height: 100%;
      width: 100px;
      position: absolute;
      left: 10%;
      z-index: 10;
      background: linear-gradient(
        90deg,
        rgb(235, 235, 235),
        rgba(235, 235, 235, 0)
      );
    }
    .rightFade {
      height: 100%;
      width: 100px;
      position: absolute;
      right: 10%;
      z-index: 10;
      background: linear-gradient(
        90deg,
        rgba(235, 235, 235, 0.1),
        rgb(235, 235, 235)
      );
    }
    .carimagediv {
      margin-top: 0px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .fpcarcaption {
      background-color: rgba(0, 0, 0, 0);
      font-size: 20pt;
      font-family: $headerFont;
      color: $offblack;
      position: absolute;
      bottom: 0;
    }
  }
  .gallery {
    overflow: hidden;
    width: 100vw !important;
    margin: 0 !important;
    padding: 0 !important;
    .galleryItem {
      padding: 0;
      height: 350px;
      overflow: hidden;
      img {
        max-width: 100%;
        object-fit: cover;
      }
      .portrait {
        transform: rotate(0deg);
        max-width: 200% !important;
      }
      .portraitWash {
        margin-left: -45%;
        margin-top: -650px !important;
      }
      .portraitCounter {
        margin-left: -65%;
        margin-top: -370px;
      }
    }
  }

  .contact-form {
    margin-top: 100px;
    margin-bottom: 100px;
    font-family: $subFont;
    h2 {
      font-family: $headerFont;
      text-align: center;
      margin-bottom: 40px;
      span {
        font-size: 45pt;
        margin-bottom: 20px;
      }
    }
    input {
      border-radius: 3px;
      border: none;
      padding: 5px 15px;
    }
    select {
      padding: 8px;
      width: 250px;
    }
    .textarea {
      border-radius: 3px;
      border: none;
      height: 150px;
      margin-top: 17px;
    }
    .submitBtn {
      // background-color: rgb(27, 82, 171);
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .footerContainer {
    width: 100vw;
    height: 330px;
    background-color: rgba(50, 50, 50, 1);
    color: $offwhite;
    margin-top: 50px;
    padding-top: 30px;
    font-family: $subFont;
    .container {
      margin-top: -10px;
    }
    .t3-logo {
      height: 80px;
      margin-bottom: 20px;
      margin-top: 15px;
    }
    .cccplug {
      margin-top: 10px;
      justify-content: center;
      color: $offwhite !important;
      opacity: 0.75;
    }
  }
}

.login-form__content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(250, 250, 250, 0.88);
  .admin-login-child {
    margin-top: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 400px;
    width: 450px;
    margin-left: -250px;
    margin-top: -200px;
    background-color: whitesmoke;
    padding: 50px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    h1 {
      font-family: $headerFont;
      margin-bottom: 30px;
    }
    label {
      font-family: $subFont;
      input {
        width: 350px;
      }
    }
    button {
      font-family: $subFont;
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 3px;
      padding: 10px 30px;
      margin-top: 30px;
      transition: 0.2s ease all;
      &:hover {
        margin-top: 26px;
      }
    }
  }
}

.admin__content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(250, 250, 250, 0.88);
  img {
    width: 150px;
    position: absolute;
    left: 30px;
    top: 30px;
  }
  h1 {
    font-family: $headerFont;
    margin-bottom: 50px;
    position: absolute;
    top: 10vh;
    left: 15vw;
    span {
      font-family: $subFont;
      font-size: 18pt;
      margin-bottom: 20px;
      margin-left: 50px;
    }
  }
  .admin-child {
    position: absolute;
    top: 18vh;
    left: 10vw;
    height: 78vh;
    width: 80vw;
    background-color: whitesmoke;
    padding: 20px 50px;
    overflow-x: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    table {
      width: 100%;
      tr {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
      .headRow {
        border-top: none;
      }
      th {
        font-family: $subFont;
        font-size: 18pt;
        padding-top: 20px;
        word-wrap: normal;
      }
      button {
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: white;
        padding: 10px;
        margin: 5px 0px;
        border-radius: 2px;
        // &:hover {
        //   background-color: white;
        // }
      }
    }
  }
}

.APPcontainer {
  margin: 0 !important;
  padding: 0 !important;
  color: $offblack;
}

@media only screen and (max-width: 1500px) {
  .home__content-wrapper {
    .floorPlansContainer {
      padding-top: 20px;
      button {
        font-size: 11pt !important;
        padding: 5px 10px !important;
      }
    }
  }
  .gallery {
    .galleryItem {
      img {
        max-width: 150% !important;
        object-fit: cover;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .navbar {
    height: 70px;
    .navRow {
      height: 70px;
      .navItem {
        margin-top: 20px;
        padding: 0;
        .navheader {
          position: absolute;
          width: 50%;
          left: 50%;
          margin-left: -25%;
          display: flex;
          justify-content: center;
        }
      }
    }
    .navMobile {
      visibility: collapse !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
    }
    h1 {
      font-size: 20pt;
    }
    img {
      height: 55px !important;
      margin-top: -30px !important;
      margin-left: -6px !important;
    }
  }
  .home__content-wrapper {
    width: 100vw;
    .fullcar {
      height: 65vh !important;
      .slide {
        height: 65vh !important;
        .carousel-indicators {
          margin-bottom: -35px;
        }
      }
      .carimagediv {
        margin-top: 50px;
        height: 65vh;
        .carimage {
          position: absolute;
          top: 10px;
          transform: scaleX(-1);
          height: 100%;
        }
        .carimage1 {
          top: 10px;
          height: 100%;
        }
        .carimage2 {
          top: 10px;
          margin-left: 0px;
          height: 100%;
        }
      }
      .carcaption {
        height: 120px;
        h3 {
          font-size: 22pt;
        }
        p {
          visibility: collapse;
        }
      }
    }
    .welcomeContainer {
      margin-bottom: 0;
      margin-top: 120px;
      padding: 0;
    }
    .welcomeHeader {
      font-size: 27pt !important;
      font-family: $subFont !important;
      padding: 40px 30px;
      margin-top: -70px;
      width: 100vw;
      margin-bottom: 0px !important;
      font-weight: 900;
      color: $offblack;
      background-color: whitesmoke;
      text-align: center;
      span {
        font-size: 15pt !important;
      }
    }
    .divRight {
      padding: 30px !important;
      // height: 300px;
      h2 {
        width: 80% !important;
        margin-left: 10%;
        text-align: center;
        font-size: 16pt !important;
      }
      ul {
        margin-left: -20px !important;
        width: 95vw;
        li {
          padding-right: 15px;
          font-size: 13px;
        }
      }
    }
    .gallery {
      visibility: collapse;
      height: 0px !important;
      .galleryItem {
        .portrait {
          transform: rotate(0deg);
          max-width: 200% !important;
        }
        .portraitWash {
          margin-left: -45%;
          margin-top: -400px !important;
        }
        .portraitCounter {
          margin-top: -200px;
        }
      }
    }
    .floorPlansContainer {
      width: 100vw;
      margin-top: 0;
      padding-top: 60px !important;
      padding-bottom: 45px !important;
      background-color: whitesmoke;
      box-shadow: 2px 2px 4px rgba(100, 100, 100, 0.2);
      h2 {
        margin-left: 0px;
        font-size: 25pt;
      }
      .col-sm-12 {
        padding-left: 10px;
        padding-right: 10px;
      }
      .floor-plans {
        padding-top: 30px;
        padding-left: 0px;
        padding-right: 0px;
        .floorPlanTable {
          margin-top: 0px;
          .bed-bath-head-mobile {
            display: table-cell;
          }
          .bed-bath-head-dtop {
            display: none;
          }
          thead {
            tr {
              border-bottom: 1px solid rgba(50, 50, 50, 0.9) !important;
            }
            th {
              border-top: none;
              border-bottom: none;
              padding: 5px 5px;
              text-align: center;
              font-size: 10pt !important;
              font-family: $subFont;
            }
            .desc-header {
              display: none;
            }
          }
          .desc-cell {
            display: none;
          }
          .tableCell {
            font-size: 8pt !important;
            color: rgb(50, 50, 50) !important;
            text-align: center;
            vertical-align: middle;
            padding: 12px 5px;
            .fpLink {
              // background-color: rgba(0, 0, 0, 0) !important;
              // color: rgb(27, 82, 171);
              padding: 3px 3px !important;
            }
          }
        }
      }
      button {
        font-size: 8pt !important;
        width: 65px;
        padding: 10px 0px !important;
        text-align: center;
        padding: 5px 10px;
      }
    }
  }
  .modal-container {
    h1 {
      font-family: $headerFont;
      margin-left: 20px;
    }
    .modal-body {
      height: 60vh;
      img {
        width: 100%;
      }
      iframe {
        height: 365px !important;
      }
      p {
        // visibility: collapse;
      }
    }
  }
  .contact-form {
    margin-top: 50px !important;
    margin-bottom: 70px !important;
    h2 {
      font-size: 14pt !important;
      width: 80%;
      margin-left: 10%;
      span {
        font-size: 25pt !important;
      }
    }
  }
  .footerContainer {
    height: 330px;
    margin-top: 20px !important;
    .cccplug {
      margin-top: 10px !important;
      margin-left: 0px;
      color: $offwhite !important;
      opacity: 0.5 !important;
    }
  }
}
